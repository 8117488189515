<template>
    <div v-if="!acknowledged">
        <v-card
            flat
            color="#262626"
            dark
            tile
            class="pa-2 px-4"
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'"
        >
            <span class="align-self-center">{{ noticeText }}</span>
            <v-spacer v-if="!$vuetify.breakpoint.smAndDown" />
            <v-divider
                v-if="!$vuetify.breakpoint.smAndDown"
                class="ml-6 mr-3"
                vertical
            />
            <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''"
                class="text-capitalize subtitle-2 mr-6 align-self-center"
                color="white"
                light
                min-width="112"
                @click="acknowledged = true"
            >
                Disable Cookies
            </v-btn>

            <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''"
                class="text-capitalize subtitle-2 font-weight-bold align-self-center"
                color="success"
                min-width="112"
                @click=";(acceptedCookies = true), (acknowledged = true)"
            >
                Allow Cookies
            </v-btn>
        </v-card>
    </div>
</template>

<script>
export default {
    name: `HomeSystemBar`,
    data: () => ({
        acknowledged: false,
        acceptedCookies: false,
        noticeText: `By clicking “Allow Cookies", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.`
    }),
    computed: {
        cookieChange() {
            return this.acceptedCookies + this.acknowledged
        }
    },
    watch: {
        acknowledged() {
            localStorage.setItem('accept-gsp-ui-cookies', this.acceptedCookies)
        }
    },
    mounted() {
        if (localStorage.getItem('accept-gsp-ui-cookies') !== null) {
            this.acceptedCookies = localStorage.getItem('accept-gsp-ui-cookies')
            this.acknowledged = true
        }
    }
}
</script>
